<template>
  <div class="page-box">
    <a-spin :spinning="loading">
      <div class="title">订货商品</div>
      <a-table
        v-if="data"
        row-key="orderWholesaleItemId"
        :columns="columns"
        :data-source="[{ ...data.item, orderState: data.orderState }]"
        :pagination="false"
        size="small"
      >
        <template #product="item">
          <div class="goods-info">
            <base-img
              class="goods-img"
              :src="item.productSkuPic || item.productMainPic"
              width="60"
              height="60"
              oss_style="60_60"
            />
            <div>
              <p class="goods-name el2">{{item.productName}}</p>
              <p class="sku-info">规格：{{item.productSkuName}}</p>
            </div>
          </div>
        </template>
      </a-table>
      <div class="title">订单摘要</div>
      <a-descriptions v-if="data" bordered size="small">
        <a-descriptions-item label="订单号">{{data.orderNo}}</a-descriptions-item>
        <a-descriptions-item label="下单时间">{{data.item.createTime}}</a-descriptions-item>
        <a-descriptions-item label="付款完成时间">{{data.payTime}}</a-descriptions-item>
        <a-descriptions-item label="订单状态">{{handleOrderState('' + data.orderState)}}</a-descriptions-item>
        <a-descriptions-item label="实付款">{{data.realAmount}}元（含运费：{{data.logisticsAmount}}元）</a-descriptions-item>
        <a-descriptions-item label="供货店铺">{{data.shopName}}</a-descriptions-item>
        <a-descriptions-item label="联系人">{{data.linkMan}}</a-descriptions-item>
        <a-descriptions-item label="联系电话">{{data.linkPhone}}</a-descriptions-item>
      </a-descriptions>

      <a-form-model
        style="margin-top: 20px"
        ref="form"
        :model="model"
        :rules="rules"
        layout="horizontal"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        :colon="false"
      >
        <a-form-model-item
          label="售后方式："
          prop="returnMethod"
        >
          <a-select
            v-model="model.returnMethod"
            placeholder="请选择"
            style="width: 220px"
          >
            <a-select-option :value="1" key="1">仅退款</a-select-option>
            <a-select-option v-if="data && data.orderState !== 1" :value="2" :key="2">退货退款</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="申请售后原因："
          prop="returnCause"
        >
          <a-auto-complete
            style="width: 220px"
            v-model="model.returnCause"
            placeholder="请输入"
            :data-source="[
              '印刷问题',
              '发票、赠品、折扣',
              '运费差价',
              '破损/污渍/划痕/变形',
              '颜色/款式与描述不符',
              '材质/成分与描述不符',
              '材质硬度/承重与描述不符',
              '少货（不含少配件）',
              '少配件',
              '尺寸/型号/容量与描述不符',
              '漏气/漏液不密封问题',
              '脱胶/配件不匹配做工问题',
              '食品包装安全/异味问题',
            ]"
          ></a-auto-complete>
        </a-form-model-item>
        <a-form-model-item
          v-if="model.returnMethod === 2"
          label="退货数量："
          prop="returnCount"
        >
          <a-input
            v-model="model.returnCount"
            suffix="件"
            style="width: 220px"
            placeholder="请输入"
          />
        </a-form-model-item>
        <a-form-model-item
          label="退款金额："
          prop="returnAmount"
        >
          <a-input
            suffix="元"
            v-model="model.returnAmount"
            style="width: 220px"
            placeholder="请输入"
          />
        </a-form-model-item>
        <a-form-model-item
          label="退款运费："
          prop="returnLogisticsAmount"
        >
          <a-input
            suffix="元"
            v-model="model.returnLogisticsAmount"
            style="width: 220px"
            placeholder="请输入"
          />
        </a-form-model-item>
        <a-form-model-item
          label="退款说明："
          prop="returnDescription"
        >
          <a-input
            v-model="model.returnDescription"
            style="width:400px"
            placeholder="请输入"
            type="textarea"
          />
        </a-form-model-item>
        <a-form-model-item
          label="上传凭证："
          prop="proofPics"
        >
          <upload-img :file-list.sync="model.proofPics" />
          <div class="tip">您反馈的问题，请提供相关截图（若有多个，建议分别拍照）</div>
        </a-form-model-item>

        <br />

        <a-form-model-item label=" ">
          <a-button type="primary" @click="submit" :loading="submitting">提交申请</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import { onMounted, reactive, ref, computed, watch } from '@vue/composition-api'
import { order } from '@/api'
import { orderStates } from '@/utils/constants'
import { closePage, wrapAwait } from '@/utils/tools'
import { message } from 'ant-design-vue'
import NP from 'number-precision'

export default {
  name: 'PageRightProtection',
  props: {
    orderId: String,
    orderItemId: String,
  },
  setup (props, { root }) {
    onMounted(() => {
      getData()
    })
    const data = ref(null)
    const loading = ref(false)
    async function getData () {
      loading.value = true
      const [err, d] = await wrapAwait(order.getBatchOrderDetail(props.orderId))
      loading.value = false
      if (!err) {
        data.value = d
      }
    }

    const columns = [
      {
        title: '商品',
        scopedSlots: {
          customRender: 'product'
        }
      },
      {
        title: '单价（元）',
        dataIndex: 'price'
      },
      {
        title: '数量',
        dataIndex: 'count'
      },
      {
        title: '可退金额（元）',
        dataIndex: 'subtotalAmount'
      },
      {
        title: '商品状态',
        dataIndex: 'orderState',
        customRender: x => handleOrderState(x + '')
      },
    ]

    function handleOrderState (value) {
      const matching = orderStates.find(x => x.value === value)
      return matching ? matching.name : ''
    }

    const model = reactive({
      returnCause: '',
      returnMethod: undefined,
      returnCount: '',
      returnAmount: '',
      returnLogisticsAmount: '',
      returnDescription: '',
      proofPics: [],
      orderWholesaleItemId: props.orderItemId,
    })
    const rules = computed(() => ({
      returnCause: [
        { required: true, message: '请输入' },
      ],
      returnMethod: [
        { required: true, message: '请选择' },
      ],
      returnCount: [
        { required: true, message: '请输入' },
        { pattern: /^[1-9][0-9]*$/, message: '格式不正确' },
        {
          validator: (_, value, callback) => {
            if (model.returnMethod !== 2) return callback()
            if (data.value && +value <= +data.value.item.count) {
              callback()
            } else {
              // eslint-disable-next-line
              callback('退货数量不能超过购买数量')
            }
          }
        }
      ],
      returnAmount: [
        { required: true, message: '请输入' },
        { pattern: /^[0-9]+(\.[0-9]+)?$/, message: '格式不正确' },
        {
          validator: (_, value, callback) => {
            if (model.returnMethod === 2) {
              if (model.returnCount === '' || Number.isNaN(+model.returnCount)) {
                // eslint-disable-next-line
                return callback('请先填写退货数量')
              }
              if (data.value && +value <= NP.times(+model.returnCount, +data.value.item.price)) {
                callback()
              } else {
                // eslint-disable-next-line
                callback('退款金额不能超过退款数量*单价')
              }
            } else {
              if (+value <= +data.value.realAmount) {
                callback()
              } else {
                // eslint-disable-next-line
                callback('退款金额不能超过实付金额')
              }
            }
          },
          trigger: 'blur'
        }
      ],
      returnLogisticsAmount: [
        { required: true, message: '请输入' },
        { pattern: /^[0-9]+(\.[0-9]+)?$/, message: '格式不正确' },
        {
          validator: (_, value, callback) => {
            if (data.value && +value <= +data.value.logisticsAmount) {
              callback()
            } else {
              // eslint-disable-next-line
              callback('退款运费不能超过实付运费')
            }
          }
        }
      ]
    }))

    // 售后方式选仅退款，把退货数量置零
    watch(() => model.returnMethod, returnMethod => {
      if (returnMethod === 1) {
        model.returnCount = '0'
      } else {
        model.returnCount = ''
      }
    })

    watch(() => model.returnCount, returnCount => {
      if (model.returnMethod === 2 && returnCount && !Number.isNaN(returnCount)) {
        model.returnAmount = NP.times(+returnCount, +data.value.item.price)
      }
    })

    const submitting = ref(false)
    const form = ref(null)
    async function submit () {
      const valid = await form.value.validate()
      if (!valid) return
      submitting.value = true
      const [err] = await wrapAwait(order.rightProtectionRequest({
        ...model,
        proofPics: model.proofPics.join(',')
      }))
      submitting.value = false
      if (err) {
        message.error(err.message || '提交失败')
      } else {
        message.success('提交成功')
        root.$bus.$emit('right-protection-done')
        closePage('/order/batch/buy')
      }
    }

    return {
      loading,
      data,
      columns,
      handleOrderState,
      model,
      rules,
      submit,
      submitting,
      form,
    }
  }
}
</script>

<style lang="less" scoped>
.goods-info {
  display: flex;
  align-items: center;
  .goods-img {
    margin-right: 12px;
  }
  .goods-name {
    flex: none;
    max-width: 126px;
    font-size: 14px;
    line-height: 22px;
    color: #0066FF;
  }
  .sku-info {
    max-width: 130px;
    font-size: 12px;
  }
}
.title {
  font-size: 16px;
  color: #333;
  padding: 15px 0 5px 0;
}
.tip {
  font-size: 12px;
  color: #999;
}
</style>
